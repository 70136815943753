import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p style={{ marginBottom: "600px" }}>
      You just hit a route that doesn&#39;t exist... Please return{" "}
      <Link to="/">home</Link>
    </p>
  </Layout>
)

export default NotFoundPage
