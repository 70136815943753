import React from "react"
import Footer from "./Footer"
import NavBar from "./NavBar"
import favicon from "../photos/favicon.ico"
import { Helmet } from "react-helmet"

import MobileNavBar from "./MobileNavBar"

const styles = {
  websiteContainer: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    margin: "0px",
    padding: "0px",
  },
  footerMargin: {
    marginBottom: "15rem",
  },
}

const Layout = props => {
  return (
    <div style={styles.websiteContainer}>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <NavBar />
      <MobileNavBar />
      <div style={styles.footerMargin}>{props.children}</div>
      <Footer />
    </div>
  )
}

export default Layout
// <div className="content-main">{props.children}</div>
